@import "src/colorVariables.scss";
input[type="checkbox"] {
  display: none;
}
.vfi-checkbox {
  display: inline-block;
  width: 14px;
  height: 14px;
  background-color: white;
  box-shadow: 0px 0px 2px 2px rgba(162, 162, 162, 0.3);
  position: relative;
  transition: ease-in-out 0.2s;
}
.vfi-checkbox:hover {
  background-color: rgba(162, 162, 162, 0.3);
}
input:checked ~ .vfi-checkbox {
  background-color: #009fff;
  box-shadow: none;

  .left-shorter {
    position: absolute;
    top: 7px;
    left: 2px;
    width: 5px;
    height: 2px;
    transform: rotate(45deg);
    background-color: white;
  }

  .right-longer {
    position: absolute;
    top: 6px;
    left: 4px;
    width: 8px;
    height: 2px;
    transform: rotate(-45deg);
    background-color: white;
  }
}
.vfi-checkbox-2 {
  display: inline-block;
  // width: 14px;
  // height: 14px;
  width: 20px;
  height: 20px;
  box-shadow: inset 0px 0px 0px 2px #ebedf2;
  position: relative;
  transition: ease-in-out 0.2s;
  cursor: pointer;
  &.checked {
    background-color: black;
  }
}
.vfi-radio-2 {
  width: 18px;
  height: 18px;
  border: 2px solid black;
  border-radius: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
  .dot-inside {
    width: 8px;
    height: 8px;
    border-radius: 4px;
    transition: ease-out 0.2s;
  }
}

input:checked ~ .vfi-radio-2 {
  .dot-inside {
    background-color: black;
  }
}
.vfi-checkbox-2:hover {
  box-shadow: inset 0px 0px 0px 2px #cbcdd2;
}

input:checked ~ .vfi-checkbox-2 {
  background-color: #000000;
  border-radius: 3px;
  box-shadow: none;

  .left-shorter {
    position: absolute;
    // top: 10px;
    // left: 4px;
    // width: 6px;
    // height: 2.5px;
    top: 50%;
    left: 20%;
    width: 30%;
    height: 12.5%;
    transform: rotate(45deg);
    background-color: white;
  }

  .right-longer {
    position: absolute;
    // top: 8px;
    // left: 7px;
    // width: 10px;
    // height: 2.5px;
    top: 40%;
    left: 35%;
    width: 50%;
    height: 12.5%;
    transform: rotate(-45deg);
    background-color: white;
  }
}

.vfi-checkbox-2.has-error,
input:checked ~ .vfi-checkbox-2.has-error {
  box-shadow: inset 0px 0px 0px 2px $red;
}
