@use "../../breakpointVariables" as breakpoints;
@import "src/colorVariables.scss";

$container-max-width: 1404px;
$horizontal-padding: 20px;
$content-offset: 120px;
$tablet-content-offset: $content-offset + 70px;
$phone-content-offset: $content-offset + 70px;

.container {
  width: 100%;
  max-width: $container-max-width;
  height: 100%;
  margin: 0 auto;

  position: relative;

  background-color: #fff;

  font-family: "Source Sans Pro", sans-serif;

  @media screen and (breakpoints.$sendistovanTabletReverse) {
    height: fit-content;
  }
}

.content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100% - var(--content-offset));
  overflow: auto;
  padding: 0 var(--horizontal-padding, 20px) 20px;
  @media screen and (breakpoints.$sendistovanTablet) {
    height: calc(100% - #{$tablet-content-offset});
    padding: 0px 0px 20px 0px;
  }

  @media screen and (breakpoints.$sendistovanMobile) {
    height: calc(100% - #{$phone-content-offset});
  }

  @media screen and (breakpoints.$sendistovanTabletReverse) {
    padding-bottom: 256px;
    height: fit-content;
  }
}

.auto_save {
  z-index: 1000;

  padding: 4px 8px;

  background-color: rgba(100, 100, 100, 0.1);

  border-radius: 5px;

  font-size: 12px;

  color: #333;

  user-select: none;

  pointer-events: none;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  gap: 4px;

  position: fixed;
  bottom: 16px;
  right: 32px;

  @media (breakpoints.$sendistovanTablet) {
    bottom: 108px;
  }
}

.session_wrapper {
  position: fixed;
  bottom: 0px;
  right: 50%;
}
