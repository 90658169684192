@use "../../../../../../../breakpointVariables" as breakpoints;


.container {

    padding: 0 0 40px;
    
    @media (breakpoints.$sendistovanTablet) {
        padding: 0 20px 40px;
    }

    width: 100%;

    // overflow: hidden;

    transition: 0.1s;

    .content {
        width: 550px;

        @media (breakpoints.$sendistovanMobile) {
            width: 100%;
        }

        @media (breakpoints.$sendistovanTablet) {
            width: 350px;
        }

        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        h3 {
            font-size: 14px;
            font-weight: 700;
            color: #000;
        }
    }
    
}