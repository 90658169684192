.password-text-guide {
  display: flex;
  > div {
    margin-left: 2px;
    flex: 1 1 0;
  }
  > div:last-child {
    margin-left: 30px;
  }
  ul {
    text-align: left;
    padding: 0 0 0 10px;
    color: #373737;
    font-family: "Source Sans Pro";
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
  }
}
