@import "src/colorVariables.scss";
.right-side-info {
  padding: 0;
  background-color: $dark;
  flex-basis: 0;
  color: white;
  overflow: auto;
  position: relative;
  z-index: 2;
  height: 0;
  display: flex;
  flex-direction: column;
  &.absolute {
    position: absolute;
    right: 0;
    z-index: 1;
    &.shown {
      z-index: 10;
      width: 480px;
      height: 100%;
      &.filter {
        width: 20%;
      }
    }
  }

  &.shown {
    padding: 50px 20px 0 20px;
    flex-basis: 480px;

    position: fixed;
    width: 440px;
    top: 0;
    right: 0;
    height: 100%;
    &.filter {
      flex-basis: 20%;
    }
  }

  .close {
    color: white;
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 20px;
  }

  &.filter {
    .close {
      right: unset;
      left: 20px;
    }
  }

  .title {
    text-transform: uppercase;
    width: 95%;
    text-align: center;
    font-size: 14px;
    margin-top: -28px;
    margin-bottom: 28px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .rsi-content {
    overflow: auto;
  }
  .dropdown-option {
    .drop-title {
      margin-bottom: 10px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
      svg {
        transition: ease-out 0.2s;
      }
    }
    .filter-option-content {
      transition: ease-out 0.2s;
      overflow: hidden;
      transform-origin: 0 0;
      user-select: none;
      min-height: 60px;
      display: flex;
      align-items: center;
      padding: 0 20px;
    }
    &.collapsed {
      .drop-title {
        svg {
          transform: rotateZ(180deg);
        }
      }
      .filter-option-content {
        transform: scaleY(0);
        min-height: 0;
      }
    }
  }
}
