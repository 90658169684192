@import "src/colorVariables.scss";
.custom-level-3-link {
  padding: 0 10px 30px;
  display: flex;
  align-items: center;
  gap: 1.125rem;
  .arrow {
    color: $blue;
  }
  .ck-editor {
    min-width: 100px;
  }
}
