@import "src/colorVariables.scss";
.page-section-header {
  min-height: 100px;
  display: flex;

  .back {
    display: flex;
    align-items: center;
    height: 70px;
    font-weight: 600;
    font-size: 19px;
    margin-right: 50px;
    cursor: pointer;
    transition: 0.2s ease-in-out;
  }
  .back:hover {
    color: $blue;
  }
  .one-language,
  .add-language {
    width: 70px;
    height: 50px;
    // padding: 15px 25px;
    margin: 10px;
    border-bottom: 1px solid $mid;
    color: $mid;
    font-size: 14px;
    transition: ease-in-out 0.2s;
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &.selected {
      border-bottom: 1px solid black;
      color: black;
    }
    &.disabled {
      pointer-events: none;
      opacity: 0.2;
    }
    &:hover {
      background-color: $light;
    }
    .one-language-wrap {
      display: flex;
      justify-content: center;
      align-items: stretch;
      width: 100%;
      .the-language,
      .trash {
        padding: 4px 8px;
        border-radius: 2px;
        transition: ease-out 0.05s;

        // &:hover {
        //   background-color: rgba($color: #ffffff, $alpha: 1);
        //   &.trash {
        //     color: $red;
        //   }
        // }
      }
      .trash {
        color: $mid;
      }
    }
  }
  .one-language {
  }
  .add-language {
    cursor: pointer;
  }
}
.edit-menu {
  height: calc(100% - 120px);
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.11);
  align-self: center;
  width: 100%;
  position: relative;
  transition: ease-in-out 0.5s;
  margin: auto;
  .vfi-text-area {
    background-color: $grey-menu;
    &.weather {
      pointer-events: none;
      * {
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: -moz-none;
        -o-user-select: none;
        user-select: none;
      }
    }
  }
  .cta {
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    align-items: center;
    > div {
      text-transform: uppercase;
      font-weight: 600;
      display: flex;
      font-size: 14px;
      margin-bottom: 5px;
      align-items: center;
      .language-select {
        min-width: 100px;
      }
      > div {
        padding: 5px 10px;
        border-radius: 2px;
        cursor: pointer;
      }
      > div:hover {
        background-color: $mid-between;
      }
      .discard {
        color: $red;
      }
      .show-hide-pages {
        color: $blue;
      }
    }
  }
  .redirect-icon {
    font-size: 0.8rem;
  }
  .header-menu {
    border-top: 1px solid $mid-between;
    overflow: auto hidden;
    display: flex;
    // margin: 50px 0 25px 0;
    padding: 20px 30px 20px 0;
    box-shadow: 0 8px 8px rgba(0, 0, 0, 0.06);
    align-items: center;
    position: absolute;
    width: 100%;
    .logo {
      position: sticky;
      left: 0;
      background-color: white;
      margin-right: 62px;
      padding-left: 20px;
      > div {
        display: flex;
        align-items: center;
        .image {
          height: 64px;
          img {
            height: 100%;
          }
        }
        .text {
          > div {
            min-width: 40px;
            min-height: 46px;
            padding: 4px;
            font-size: 28px;
            font-family: "VFIfonts", Fallback, sans-serif;
            font-weight: 700;
            padding-left: 12px;
            white-space: nowrap;
          }
          .active {
            background-color: $input-fill;
          }
        }
      }
    }
    .each-page {
      cursor: pointer;
      position: relative;
      padding-bottom: 28px;

      .drop {
        position: absolute;
      }
      .indicator {
        height: 40px;
        width: 40px;
        background-color: white;
        position: absolute;
        text-align: center;
        left: 50%;
        transform: rotate(45deg) translateX(-50%);
        z-index: 1;
        bottom: -50px;
      }
      .first-level {
        text-transform: uppercase;
        font-family: "Source Sans Pro";
        margin: 0 10px;
        white-space: nowrap;
        font-weight: bold;
        position: relative;
        .menu-options {
          background-color: white;
          padding: 4px 0;
          display: flex;
          align-items: center;
          justify-content: center;
          .option {
            opacity: 0;
            width: 20px;
            height: 20px;
            font-size: 17px;
            display: grid;
            place-items: center;
            margin: 0 5px;
            transition: 0.2s ease-in-out;
            border-radius: 2px;
            cursor: auto;
            background-color: unset;
            svg {
              margin-right: unset;
            }
            &:hover {
              color: #000;
            }
            &.clickable {
              &:hover {
                background-color: rgba(0, 0, 0, 0.1);
                cursor: pointer;
              }
            }
          }
          .show {
            opacity: 1;
          }
        }
      }
      .first-level {
        &:hover {
          .menu-options {
            .option {
              opacity: 1;
            }
          }
        }

        .first-title {
          display: flex;
          align-items: center;
          gap: 0.25rem;
        }
      }
    }
  }
  .close {
    position: absolute;
    right: 20px;
    top: 158px;
    z-index: 1;
    font-size: 18px;
    width: 20px;
    color: black;
    transition: color 0.2s;
    cursor: pointer;
    text-align: center;
  }
  .menu-template {
    display: flex;
    background-color: white;
    box-shadow: 0 0 20px rgb(55 55 55 / 20%);
    text-shadow: none;
    color: black;
    position: relative;
    margin: auto;
    margin-top: 100px;
    min-height: 100px;
    .react-draggable {
      z-index: 9;
      position: fixed;
      top: 0;
      left: 0;
      // width: 100%;
      // height: 100%;
    }

    .menu-options {
      position: absolute;
      right: 50px;
      top: -60px;
      height: 40px;
      padding: 4px 0 0;
      z-index: 20;

      border-radius: 2px;
      transition: 0.2s ease-in-out;
      display: flex;
      box-shadow: 0 0 20px rgb(0 0 0 / 10%) !important;
      opacity: 1;
      background-color: hsla(0, 0%, 100%, 0.5) !important;
      .option {
        width: 32px;
        height: 32px;
        font-size: 17px;
        display: grid;
        place-items: center;
        margin: 0 5px;
        transition: 0.2s ease-in-out;
        border-radius: 2px;
        cursor: pointer;
        background-color: unset !important;
        svg {
          margin-right: unset;
        }
      }
      .option:hover {
        background-color: rgba(0, 0, 0, 0.1) !important;
        color: #000;
      }
    }

    .wrap-table {
      max-height: calc(100vh - 140px);
      overflow: auto;
      width: 100%;
      table {
        width: calc(100% - 60px);
        margin: auto;
        border-collapse: collapse;

        .empty-td {
          width: 5px;
        }
        .menues {
          margin: auto;
          min-height: 500px;
          width: 100%;
          margin-top: 10px;
          position: relative;
          z-index: 2;
          .each-page {
            vertical-align: top;
            &.has-palette,
            &.has-module {
              width: 500px;
            }
            .carousel {
              &.slide {
                width: 100%;
              }
            }
            .draft {
              > div {
                opacity: 0.5;
              }
            }

            .second-level {
              width: 100%;
              padding: 0 10px 30px 10px;
              margin-right: 30px;
              position: relative;
              min-height: 46px;

              .carousel {
                width: 100%;
                display: flex;
                gap: 6px;
                .slide {
                  .carousel-control-next,
                  .carousel-control-prev {
                    span {
                      filter: brightness(0);
                      background: unset;
                    }
                    span::before {
                      font-family: "Font Awesome 5 Pro";
                      font-weight: 400;
                      content: "\f054";
                    }
                    .carousel-control-prev-icon::before {
                      font-family: "Font Awesome 5 Pro";
                      font-weight: 400;
                      content: "\f053";
                    }
                  }
                  .carousel-indicators {
                    left: unset;
                    margin-right: 15px;
                    li {
                      background-color: rgba(0, 0, 0, 0.7);
                      width: 6px;
                      height: 6px;
                      border-radius: 6px;
                      display: block;
                      border: unset;
                    }
                    li:only-child {
                      display: none;
                    }
                  }
                }
                .carousel-item {
                  height: 100%;
                  .option {
                    position: absolute;
                    z-index: 4;
                    right: 15px;
                    top: 15px;
                    padding: 10px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: rgba(white, 0.5);
                    cursor: pointer;
                    svg {
                      width: 20px;
                      height: 20px;
                      padding: 2px;
                      border-radius: 2px;
                    }
                  }
                  .option:hover {
                    svg {
                      background-color: rgba($mid, 0.2);
                    }
                  }
                  .vfi-text-area {
                    height: 100%;
                  }
                  .empty-slider {
                    height: 100%;
                    background-color: $grey-menu;
                    min-height: 200px;
                  }
                }
              }
              &.edit-mode:not(.ckeditor) {
                background-color: rgba($mid, 0.1);
              }
              &.ckeditor {
                // min-width: 500px;

                margin-top: 6px !important;
                padding: 0;
                position: relative;
                .third-level {
                  display: none;
                }
              }

              &.palette {
                padding: 0;
                display: flex;
                align-items: flex-end;
                justify-content: center;
                position: relative;
                .title {
                  position: relative;
                  font-family: "Libre Baskerville";
                  font-size: 22px;
                  font-weight: bold;
                  letter-spacing: 0;
                  line-height: 30px;

                  text-shadow: 1px 1px 1px 0 rgba(0, 0, 0, 0.3);
                }
                .third-level {
                  display: none !important;
                }
              }
              &.list-palette {
                justify-content: space-between;
                align-items: center;
                padding: 40px !important;
                background-color: $grey-menu;
                margin-top: 6px;
                .list {
                  svg {
                    height: 20px;
                    width: 20px;
                  }
                }
              }
              &.image-palette {
                margin-top: 6px;
                height: 300px;

                .image {
                  position: absolute;
                  display: block;
                  height: 100%;
                  width: 100%;
                  img {
                    filter: brightness(0.8);
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                  }
                }
                .title {
                  color: #ffffff;
                  margin-bottom: 30px;
                  text-align: center;
                }
              }
              .title {
                display: flex;
                align-items: center;
                color: #373737;
                font-size: 20px;
                font-weight: bold;
                letter-spacing: 0.5px;
                line-height: 25px;
                gap: 1.125rem;
                .menu-hidden {
                  opacity: 0.5;
                  margin-left: 10px;
                  font-size: 16px;
                  display: flex;
                  align-items: center;
                }
              }
              .menu-options {
                opacity: 0;
                background-color: white;
                box-shadow: unset;
                right: unset;
                top: unset;
                height: unset;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
                padding: 4px 0;
                .option {
                  width: 20px;
                  height: 20px;
                }
              }

              .third-level {
                margin-top: 18px;
                display: flex;
                font-family: "Libre Baskerville";
                font-style: italic;
                font-weight: normal;
                margin-bottom: 8px;
                cursor: pointer;
                font-size: 1rem;
                flex-direction: column;
                row-gap: 8px;
                .dropSub {
                  position: relative;
                  align-items: center;
                  gap: 1.125rem;
                  > div:not(.subMenuOptions) {
                    pointer-events: none;
                  }
                  .subMenuOptions {
                    opacity: 0;
                    transform: unset;
                    position: unset;
                  }
                }
                .dropSub:hover {
                  .subMenuOptions {
                    opacity: 1;
                  }
                }
                > div {
                  display: flex;
                }
                .arrow {
                  svg {
                    color: $blue;
                  }
                }
                .menu-hidden {
                  margin-left: 10px;
                  opacity: 0.5;
                }
              }
            }
            .second-level:last-of-type {
              margin-bottom: 40px;
              // margin-top: 0px;
            }
            .second-level:first-child {
              margin-top: 44px;
            }
            .second-level:hover {
              &.edit-mode {
                background-color: rgba($mid, 0.2);
              }
              .menu-options {
                opacity: 1;
              }
            }
            &.has-module {
              h1,
              h2,
              h3,
              p {
                margin: 0;
              }
              .second-level {
                .title,
                .third-level {
                  // display: none;
                }
              }
            }
          }
        }
      }
    }
  }
  .menu-template:hover {
    .menu-options {
      opacity: 1;
    }
  }
  .disable {
    opacity: 0 !important;
  }
}

.image-palette {
  height: 300px;

  .image {
    position: absolute;
    display: block;
    height: 100%;
    width: 100%;
    img {
      filter: brightness(0.8);
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .title {
    color: #ffffff;
    margin-bottom: 30px;
    text-align: center;
  }
}
