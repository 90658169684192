.warningContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  .warningContent {
    background-color: #ffffff;
    padding: 20px 30px;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 80%;

    .warningHeader {
      font-size: 1.5rem;
      margin: 0 0 15px 0;
      color: #333;
    }
    .warningText {
      font-size: 1rem;
      margin: 0 0 20px 0;
      color: #666;
      line-height: 1.5;
    }
    .warningButton {
      padding: 10px 20px;
      font-size: 1rem;
      color: #ffffff;
      background-color: #007bff;
      border: none;
      cursor: pointer;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
      transition: background-color 0.3s;
    }
  }
}
