@import "src/colorVariables.scss";
.is-articles-content,
.whatson-content {
  overflow-y: auto;
  height: 100%;
  // .add-icon{
  //   color: $blue;
  //   font-size: 14px;
  //   cursor: pointer;
  //   transition: ease-in-out 0.2s;
  // }
  // .add-icon:hover{
  //     color: $light-blue;
  // }
  .is-articles-display,
  .whatson-display {
    .header-panel {
      padding: 57px 40px 20px 60px;
      position: sticky;
      top: 0;
      background-color: white;
      z-index: 1;
      .header-title {
        display: inline-block;
        width: 25%;
        h1 {
          font-size: 30px;
          opacity: 0.98;
          font-weight: bold;
        }
      }

      .status-filter-parent {
        display: inline-block;
        width: 55%;

        .status-filter {
          cursor: pointer;
          user-select: none;
          margin: 0 10px;
          color: black;
          .status-filter-amount {
            color: $mid;
          }
          transition: ease-in-out 0.2s;
        }
        .status-filter.selected {
          color: $blue;
        }
      }

      .add-new-media-parent {
        display: inline-block;
        width: 20%;
        text-align: right;
        .add-new-media {
          display: inline-block;
          background-color: #009fff;
          color: white;
          vertical-align: middle;
          font-size: 17px;
          font-weight: 600;
          height: 50px;
          width: 160px;
          position: relative;
          cursor: pointer;
          .text {
            width: 160px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            text-align: center;
          }
        }
      }

      .upload-box {
        margin-top: 30px;
        height: 150px;
        border: 2px dashed rgba(162, 162, 162, 0.5);
        position: relative;
        overflow: hidden;
        transition: height 0.5s;
        .text-content {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);

          .first {
            text-align: center;
            font-size: 18px;
            font-weight: 600;
          }
          .second {
            text-align: center;
            font-size: 14px;
            color: #a2a2a2;
          }
        }
      }

      .upload-box.hidden {
        height: 0;
        border: 0;
      }

      .search-parent {
        display: inline-block;
        width: 50%;
        margin-top: 20px;
        vertical-align: top;
        .search {
          position: relative;
          width: 50%;
          input {
            //background-color: rgba(162,162,162,0.1);
            //border-radius: 2px;
            //border: 0;
            //padding: 12px 6px;
            width: 100%;

            ::placeholder {
              font-size: 2px;
            }
          }
          .magnifying-glass {
            position: absolute;
            top: 45%;
            right: 12px;
            transform: translateY(-50%);
            pointer-events: none;
          }
        }
      }

      .all-sort-and-filter {
        display: inline-block;
        width: 50%;
        // width: 100%;
        text-align: right;
        margin-top: 20px;
        position: relative;
        .image-show,
        .list-show,
        .filter-button {
          cursor: pointer;
          display: inline-block;
          margin-left: 5px;
          padding: 5px 10px;
          transition: ease-in-out 0.5s;
          border-radius: 2px;
          .icon {
            margin: auto;
          }
        }

        .delete-selected-button {
          margin-top: 10px;
          color: red;
          font-size: 0.875rem;
          vertical-align: bottom;
          position: absolute;
          right: 0;
          cursor: pointer;
          opacity: 1;
          transition: opacity 0.2s;
          display: flex;
          align-items: center;
          background-color: white;
          svg {
            padding-left: 5px;
          }
          &:hover {
            text-decoration: underline;
          }
        }

        .delete-selected-button.hidden {
          user-select: none;
          opacity: 0;
          pointer-events: none;
        }

        .image-show.selected,
        .list-show.selected {
          color: #009fff;
        }

        .filter-button {
          padding: 9px 15px 9px 20px;
          border: 1px solid transparent;
          border-radius: 20px;
          user-select: none;
          background-color: $blue;
          color: white;
          .slide-icon {
            margin-left: 15px;
          }
        }
        .filter-button.hidden {
          padding: 9px 15px 9px 20px;
          border: 1px solid rgba(162, 162, 162, 0.1);
          border-radius: 20px;
          user-select: none;
          background-color: white;
          color: black;
          cursor: pointer;
          .slide-icon {
            margin-left: 15px;
          }
        }
        .filter-button.hidden {
          padding: 9px 15px 9px 20px;
          border: 1px solid rgba(162, 162, 162, 0.1);
          border-radius: 20px;
          user-select: none;
          cursor: pointer;
          .slide-icon {
            margin-left: 15px;
          }
        }
      }
      .filter-panel {
        height: 210px;
        transition: ease-in-out 0.5s;
        overflow: hidden;
        background-color: $input-fill;
        margin-top: 10px;
        padding: 15px;
        opacity: 1;

        .media-type {
          vertical-align: top;
          display: inline-block;
          margin-right: 40px;
        }
      }
      .filter-panel.hidden {
        margin-top: 0;
        height: 0;
        display: none;
        padding: 0 15px 0 15px;
        opacity: 0;
      }
    }

    .blur-screen {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba($color: #000000, $alpha: 0.2);
      z-index: 1;
    }
    .blur-screen.hidden {
      display: none;
    }
  }

  .is-articles-create-new,
  .whatson-create-new {
    height: 100%;
    .header-display {
      display: flex;
      padding: 30px 20px 0 20px;
      border-bottom: 1px solid $light;
      height: 130px;
      overflow-x: auto;
      gap: 15px;
      flex-shrink: 0;
      @media ($phone) {
        padding-top: 40px;
        height: 140px;
      }
      .is-articles-step,
      .whatson-step {
        display: inline-block;
        margin: 0 auto;
        position: relative;

        .error {
          position: absolute;
          top: 0;
          right: 0;
          font-size: 10px;
          background-color: #ef2533;
          color: white;
          width: 14px;
          height: 14px;
          border-radius: 7px;
          display: flex;
          justify-content: center;
        }

        .w-bubble {
          transition: ease-in-out 0.2s;
          width: 58px;
          height: 58px;
          background-color: $light;
          border-radius: 29px;
          position: relative;
          margin: auto;
          margin-bottom: 10px;
          .edit_true {
            background-color: orange;
            border-radius: 50%;
            width: 18px;
            height: 18px;
            margin: 0;
            padding: 0;
            position: absolute;
            left: 85%;
            top: 0%;
            transform: translate(-50%);
            text-align: center;
            font-size: 11px;
            color: white;
          }
          svg,
          .b-text {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            font-size: 24px;
          }
          .b-text {
            font-size: 12px;
          }
        }
        p {
          text-align: center;
          color: $mid;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          white-space: nowrap;
        }
      }
      .is-articles-step:hover:not(.disabled),
      .whatson-step:hover:not(.disabled) {
        cursor: pointer;
        .w-bubble {
          background-color: $mid;
        }
      }
      .is-articles-step.selected,
      .whatson-step.selected {
        .w-bubble {
          background-color: black;
          svg,
          .b-text {
            color: white;
          }
        }
        p {
          color: black;
        }
      }
      .is-articles-step.bubble-error,
      .whatson-step.bubble-error {
        .w-bubble {
          // box-shadow: 0 0 15px $red;
        }
      }
    }
    .blur-screen {
      position: fixed;
      background-color: rgba(black, 0.4);
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 2;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .blur-screen.hidden {
      display: none;
    }
  }
}

.is-articles-list,
.whatson-list {
  flex: 1;
  padding: 10px 40px 20px 60px;
  .is-articles-table,
  .whatson-table {
    width: 99%;
    // margin: 0 30px;

    th {
      font-size: 14px;
      font-weight: 600;
      color: #a2a2a2;
      opacity: 0.98;
      padding: 10px 0;
      user-select: none;
      transition: ease-in-out 0.2s;
      svg {
        margin-left: 9px;
      }
    }
    th.name,
    th.author,
    th.locations,
    th.file-size,
    th.date,
    th.hearted {
      cursor: pointer;
    }
    th.hearted {
      text-align: center;
    }
    th:hover {
      color: #626262;
    }
    th.sorted-by {
      color: black;
    }
    th.favourite {
      text-align: center;
    }
    th.check-select {
      text-align: center;
      label {
        margin: 0;
        padding: 0 0 0 10px;
      }
    }
    tbody {
      tr {
        &.transparent {
          opacity: 0.5;
        }
        &:hover {
          td {
            background-color: $light;
          }
        }
        td {
          padding: 10px 0;
          border-top: 1px solid rgba(162, 162, 162, 0.1);
          position: relative;
          .changesIcon {
            background-color: orange;
            position: absolute;
            top: 40%;
            left: -5%;
            width: 16px;
            margin: 0;
            height: 16px;
            border-radius: 80%;
            font-size: 11px;
            color: white;
            transform: translate(-50%);
            text-align: center;
          }
        }

        td.file {
          min-width: 300px;
          width: 300px;
          cursor: pointer;
          img {
            height: 40px;
            width: 70px;
            object-fit: cover;
            vertical-align: middle;
          }
          .upload-progress {
            display: inline-block;
            vertical-align: middle;
            height: 40px;
            width: 70px;
            background-color: lightgray;
          }
          .file-info {
            display: inline-block;
            vertical-align: middle;
            margin-left: 20px;
            .name {
              margin-bottom: 3px;
              max-width: 200px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            .name.progress-bar {
              background-color: transparent;
              margin-bottom: 0;
              height: 22px;
              display: inline-block;
              progress {
                height: 5px;
                border: none;
                vertical-align: bottom;
                margin-bottom: 2px;
              }
            }
            .file-name {
              font-size: 14px;
              color: #a2a2a2;
              max-width: 300px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            .file-name.in-progress {
              font-size: 12px;
              color: #a2a2a2;
              text-overflow: ellipsis;
              width: 200px;
              //text-overflow: clip ellipsis clip 0 3ch;
              overflow: hidden;
            }
          }
          .video-icon {
            display: inline-block;
            vertical-align: middle;
            position: relative;
            .snapshot-generator {
              display: none;
            }
            .play-icon {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              font-size: 28px;
              color: rgba($color: #ffffff, $alpha: 0.75);
            }
            .play-circle {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              font-size: 28px;
              color: rgba($color: #000000, $alpha: 0.25);
            }
          }
        }
        td.author {
          max-width: 120px;
          min-width: 100px;
          .author-name {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            width: 95%;
            font-size: 15px;
            color: $dark;
          }
        }
        td.region {
          .what-region {
            font-size: 15px;
            white-space: nowrap;
            max-width: 100px;
            overflow: hidden;
            text-overflow: ellipsis;
            padding-right: 10px;
          }
        }
        td.location {
          min-width: 100px;
          max-width: 120px;
          .location-sites {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            width: 95%;
            font-size: 15px;
            color: $dark;
          }
        }
        td.size {
          min-width: 100px;
          max-width: 120px;

          .size-mb,
          .size-dimensions {
            .location-sites {
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              width: 95%;
            }
          }
          .size-dimensions {
            font-size: 14px;
            color: #a2a2a2;
          }
        }
        td.approval {
          padding-right: 10px;
          .approval-wrap {
            padding-right: 30px;
          }
        }
        td.date {
          font-size: 15px;
          color: $dark;
          min-width: 130px;
        }
        td.favourite {
          text-align: center;
          svg {
            cursor: pointer;
          }
          .hearted {
            color: red;
          }
        }
        td.check-select {
          text-align: center;
          label {
            margin: 0;
            padding: 0 0 0 10px;
          }
        }
      }
    }
  }
}
