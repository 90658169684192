.custom-level-3-link {
  .vfi-text-area {
    .ck-content {
      padding: 0;
      margin: 0;
      .excerpt {
        margin: 0 !important;
        font-weight: initial;
        font-size: 1rem;
      }
    }
  }
}
