.container {
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  gap: 10px;

  user-select: none;

  .vfi_logo {
    width: 35px;
    height: 35px;
  }

  p {
    padding: 0;
    margin: 0;

    font-weight: bold;

    // font-family: "Source Sans Pro";
    font-family: "VFIfonts";
    font-size: 1.5rem;

    color: #151616;
  }
}
