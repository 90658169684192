@import "src/colorVariables.scss";
.container {
  width: 980px;
  padding: 30px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 10px;

  .formField {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  .formField label {
    margin-bottom: 8px;
    font-size: 0.9rem;
    color: #c9c8c8;
    text-align: left;
  }

  .title {
    margin-bottom: 8px;
    font-size: 1.5rem;
    text-align: left;
  }
  label.formRow {
    display: flex;
    align-items: center;
    gap: 10px;
    color: $mid;
    font-size: 1rem;
    cursor: pointer;
    label {
      margin-bottom: 0;
    }
    p {
      margin-bottom: 8px;
    }
  }

  .buttonGroup {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }

  .cancelButton {
    width: auto;
    height: 40px;
    padding: 5px 15px;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.2s;
  }

  .editButton {
    width: auto;
    height: 40px;
    padding: 5px 15px;
    background-color: #009fff;
    font-size: 14px;
    line-height: 18px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.2s;
  }
  .errorLabel {
    color: red;
    text-align: center;
    font-size: 0.9rem;
    font-weight: 600;
  }
  // .validationErrors {
  //   color: red;
  //   display: flex;
  //   justify-content: center;
  //   margin-top: 20px;
  //   height: 15px;
  //   font-size: 12px;
  // }
}
