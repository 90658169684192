@use "../../../../../../../breakpointVariables" as breakpoints;
@import "src/colorVariables.scss";

.container {
  font-family: "Source Sans Pro", sans-serif;

  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  gap: 24px;

  .instagram_input {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    gap: 4px;

    .label {
      width: 100%;

      text-align: left;
    }
  }

  .first_section {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    gap: 4px;

    .title {
      font-size: 12px;

      color: #acacac;

      font-weight: 600;

      line-height: 15px;

      user-select: none;

      text-transform: uppercase;
    }
  }

  .content {
    padding-bottom: 124px;

    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    gap: 24px;

    transition: 0.1s;

    .information {
      text-align: left;
    }

    .profile_links {
      width: 100%;

      display: flex;
      flex-direction: column;

      gap: 8px;

      .instagram_input {
        width: 50%;

        display: grid;
        grid-template-columns: 120px 1fr;
        align-items: center;

        @media (breakpoints.$sendistovanTablet) {
          width: 100%;

          display: flex;
          flex-direction: column;

          grid-area: 8px;
        }
      }

      @media (breakpoints.$sendistovanTablet) {
        display: grid;
        grid-template-columns: 1fr 1fr;
      }
    }

    .image_display {
      width: 100%;

      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      grid-area: 16px;

      @media (breakpoints.$sendistovanMobile) {
        width: 100%;
      }
    }

    .image_elements {
      width: 100%;
    }
  }
}

.image_amount {
  width: 100%;

  max-width: 550px;

  @media (breakpoints.$sendistovanMobile) {
    max-width: 100%;
  }
}
