@use "../../../../../breakpointVariables" as breakpoints;
@import "src/colorVariables.scss";

.container {
  transition: opacity 0.1s ease-in-out;

  &.disabled {
    opacity: 0.5;

    pointer-events: none;
  }
}

.types_invalid_error_message {
  padding: 20px 0 0;

  text-align: left;

  color: $red;

  @media (breakpoints.$sendistovanTablet) {
    padding: 0 20px 20px 20px;
  }
}

.content {
  list-style: none;
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
  white-space: nowrap;
  margin: 60px auto 40px 0;
  padding: 0;
  // width: 60%;

  @media screen and (breakpoints.$sendistovanTablet) {
    justify-content: space-between;
    max-width: 100%;

    margin-top: unset;
    padding: 0px 20px 80px;
  }
  @media screen and (breakpoints.$sendistovanMobile) {
    columns: 1;
  }
}

.TypeCheckboxContainer {
  display: flex;
  gap: 8px;

  flex: 50%;
  min-width: 250px;

  @media screen and (breakpoints.$sendistovanMobile) {
    flex: 100%;
  }
}

.secondaryLabel {
  font-size: "Source Sans Pro", sans-serif;
  font-size: 12px;
  line-height: 22px;

  font-style: italic;
  color: rgba(162, 162, 162, 0.5);
}
