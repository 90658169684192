@import "../../colorVariables.scss";
@import "../../fonts.scss";

.whatson-common {

  font-family: 'Source Sans Pro', sans-serif;

}

.App {
  #LogoContainer {
    margin: 0 auto;

    width: 450px;
  }

  .content-forWhatson {
    .whatson-content {
      overflow-y: visible;
    }
  }

  .register-login {
    z-index: 1000;

    position: absolute;
    top: 30px;
    right: 46.5px;

    color: #000;
    text-decoration: none;

    cursor: pointer;

    user-select: none;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    gap: 10px;

    transition: 0.2s;

    &:hover {
      color: rgb(0, 159, 255);

      p {
        color: rgb(0, 159, 255);
      }
    }

    @media ($phone) {
      position: absolute;
      top: 40px;
      left: 0;
      right: 0;
    }

    p {
      margin: 0;
      padding: 0;

      color: #000;
      font-weight: bold;
      font-size: 13px;

      transition: 0.2s;
    }
  }

  .whatson-contents {
    box-sizing: border-box;
    padding: 0;
    margin: 0;

    height: 100%;
    overflow: auto;
    // background-color: white;
    .content {
      padding-bottom: 50px;
      padding-top: 150px;
      width: 50%;
      display: block;
      margin-left: auto;
      margin-right: auto;
      background-color: transparent;
      min-width: 360px;

      @media ($phone) {
        width: unset;
        max-width: unset;
        padding-left: 0;
        padding-right: 0;
      }

      @media (max-width: 900px) {
        padding-bottom: 0;
      }

      .imgcontainer {
        width: 180px;
        text-align: center;
        margin: auto;
        display: inline-block;

        font-family: "VFIfonts", Fallback, sans-serif;
        // letter-spacing: 2px;

        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 10px;

        img {
          width: 40px;
          height: 40px;
          // filter: brightness(0%);
          margin-right: 10px;
          float: left;
        }

        .text {
          float: left;
          p {
            margin: 0;
            padding: 0;
            width: 100%;
            text-align: left;
          }
          .whatson-text {
            font-size: 1.5em;

            // font-family: "Source Sans Pro";
            // font-weight: bold;
          }
          .vfi-text {
            font-size: 0.8em;

            font-weight: bold;
          }
        }
      }

      .container {
        margin: auto;
        padding-top: 20px;
        height: 40%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        gap: 20px;

        > span,
        > button {
          // min-width: 360px;
          min-width: 100%;
        }


        .verifyEmailInput {
          letter-spacing: 2px;
          padding: 10px 20px;

          max-width: 250px;
          width: 100%;

          border: none;

          border-radius: 2px;

          font-size: 16px;
          font-weight: bold;

          text-align: center;

          &.invalidCode {
            // border: 1px solid red;
            // outline: 2px solid red;

            box-shadow: 0 0 0 2px #f64e60 inset;
          }
        }

        .verifyButtons {
          padding: 10px;

          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          gap: 5px;

          .verifyEmailBtn {
            padding: 10px 20px;

            height: 45px;

            background-color: #009fff;
            color: #fff;

            transition: 0.2s;

            &:hover {
              background-color: #31b1fd;
            }
          }

          .backBtn {
            padding: 10px 20px;
            width: fit-content;

            background-color: rgba(0, 0, 0, 0);
            color: #373737;
          }

          button {
            margin: 0;
            width: fit-content;

            font-weight: bold;
          }
        }
      }

      button[type="register"] {
        font-family: Helvetica, sans-serif;
        font-weight: 500;
        font-size: calc(0.27548vw + 12.71074px);
        // background-color: $color-button-blue;
        border-radius: 2px;
        transition: ease-in-out 0.2s;
      }
      button {
        color: white;
        padding: 12px 20px;
        margin: 8px 0;
        border: none;
        cursor: pointer;
        // width: 60%;
        height: 50px;
      }
      .withoutFacebook {
        background-color: $mid;
      }
      .my-facebook-button-class {
        // min-width: 360px;
        min-width: 100%;
        height: 50px;
        color: black;
        text-align: left;
        // background-color: transparent;
        background-color: rgba(162, 162, 162, 0.1);
        border: 2px solid transparent;
        border-radius: 2px;
        position: relative;
        transition: ease-in-out 0.2s;
        img {
          top: 50%;
          transform: translate(0, -50%);
          width: 20px;
          height: 20px;
          position: absolute;
        }
        .fb-text {
          font-weight: 700;
          top: 50%;
          transform: translate(0, -50%);
          position: absolute;
          width: calc(100% - 40px);
          text-align: center;
        }
      }
      .my-facebook-button-class:hover {
        background-color: lightblue;
        border: 2px solid lightblue;
      }

      button:hover {
        opacity: 0.8;
      }

      .seperator {
        width: 100%;

        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        gap: 20px;

        color: #d3d3d3;

        &::before,
        &::after {
          content: "";
          flex: 1;
          border-bottom: 1px solid #d3d3d3;
        }
      }

      .cli {
        width: 100%;

        color: #a2a2a2;
        text-align: center;

        user-select: none;

        a {
          color: #a2a2a2;
          font-family: "Source sans pro", sans-serif;

          cursor: pointer;
        }

        &:hover {
          text-decoration: underline;
        }
      }

      //Whatson login table
      .loginTable {
        width: 100%;

        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        gap: 10px;

        h5 {
          font-family: "Source Sans Pro", sans-serif;
          font-weight: bold;

          color: #373737;
        }

        .headline1,
        .headline2 {
          text-align: left;
          margin-left: 10%;
          @media ($phone) {
            margin-right: 10%;
          }
        }
        .headline2 {
          margin-bottom: 30px;
        }

        .form {
          width: 100%;

          display: flex;
          flex-direction: column;

          gap: 20px;

          input {
            padding: 15px 30px 15px 15px;

            border: none;

            outline: none;

            background-color: #fff;
            box-shadow: inset 0 0 2px 0 rgba(0, 0, 0, 0);

            transition: 0.2s;

            &:hover {
              box-shadow: inset 0 0 2px 0 rgba(0, 0, 0, 0.2);
            }

            &:focus {
              // box-shadow: inset 0 0 5px 0 rgba(0, 0, 0, 0.05);
              // outline: 2px solid #009FFF;

              box-shadow: 0 0 0 2px #0099FF inset;
            }
          }

          div {
            p {
              text-align: left;
              margin-bottom: 5px;
              padding: 0;
              // color: $mid;
              // color: #373737;
              color: #000;
              font-size: 13px;

              font-weight: 500;

              opacity: 98%;
            }
          }

          .name {
            // padding: 0 10%;

            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;

            .inputs {
              width: 100%;

              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: flex-start;

              gap: 10px;

              .firstname,
              .lastname {
                width: 100%;
              }
            }
          }

          .containerLanda_tele {
            width: 100%;
            // padding: 0 10%;
            margin-bottom: 5px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;

            .phoneInputs {
              height: fit-content;
              width: 100%;

              display: flex;
              flex-direction: row;
              justify-content: flex-start;
              align-items: center;

              gap: 20px;

              .landakoda {
                // width: 120px;
                height: 100%;

                .landakoda2 {
                  height: 100%;
                }

                .menu {
                  width: 200px;

                  transform: translateX(-25%);
                }
              }

              .telefon {
                width: 100%;
                height: 100%;
                label,
                input {
                  width: 100%;
                  // height: 35px;
                }
              }
            }

            // .css-1dimb5e-singleValue {
            //   transform: translateX(-10px);
            // }

            // .css-1fdsijx-ValueContainer {
            //   height: 100%;
            // }

            .css-1s2u09g-control,
            .css-1mndiq5-menu,
            .css-1pahdxg-control,
            .css-319lph-ValueContainer,
            .css-1hb7zxy-IndicatorsContainer {
              width: 200px;
              height: 35px;
              min-height: unset;
              .css-qc6sy-singleValue {
                margin-top: -20px;
              }
              #react-select-3-listbox,
              .css-qc6sy-singleValue {
                width: 200px;
                text-align: left;
              }
            }
            .css-14el2xx-placeholder {
            }
            // .firstname,
            // .landakoda {
            //   margin-right: 5%;
            // }
          }
          .email,
          .confirm-email,
          .password,
          .name,
          .firstname,
          .lastname,
          .telefon,
          .fyritøka,
          .heimasíðu,
          .miðil {
            // padding: 0 10%;
            width: 100%;
            // margin-bottom: 10px;

            label,
            input {
              width: 100%;
              //height: 35px;

              border-radius: 2px;
            }

            input[type="text"].error {
              box-shadow: 0 0 0 2px #f64e60 inset;
            }

            &.missingField {
              input {
                // border: 1px solid red;
                // outline: 2px solid red;

                box-shadow: 0 0 0 2px #f64e60 inset;

                &.error {
                  // outline: none;
                }
              }
            }
          }

          .title-wrapper {
            padding-bottom: 5px;

            font-size: 12px;

            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;

            gap: 5px;

            p {
              margin: 0;
              padding: 0;
            }

            .password-requirenments {
              height: 100%;
              width: 12px;
            }

            svg {
              height: 100%;

              // margin-bottom: 5px;
            }
          }

          .miðil {
            // margin-bottom: 50px;
          }

          .email,
          .confirm-email {
            //margin-bottom: 50px;
          }

          .remember-me,
          .accept-policy {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;

            gap: 10px;

            label {
              margin: 0;
              padding: 0;

              transform: translateY(2px);

              cursor: pointer;
            }

            p {
              padding: 0;
              margin: 0;

              color: #b2b2b2;

              a {
                color: #b2b2b2;

                text-decoration: underline;
              }
            }

            &.missingField {
              .vfi-checkbox {
                border: 1px solid red;
              }
            }
          }
        }

        // .controls{
        //     display: inline-block;
        //     margin-left: 20px;
        //     .back,.forward{
        //         margin-left: 20px;
        //         width: 90px;
        //         height: 50px;
        //         border-radius: 2px;
        //         position: relative;

        //         span{
        //             position: absolute;
        //             left: 50%;
        //             top: 50%;
        //             transform: translate(-50%,-50%);
        //             font-size: 17px;
        //         }
        //     }
        //     .back{
        //         background-color: $light;
        //         color: $dark;
        //     }
        //     .forward{
        //         background-color: $blue;
        //         color: white;
        //     }
        // }

        .buttons {
          height: 50px;
          width: 100%;
          margin-bottom: 30px;

          .login,
          .register {
            width: 100%;

            border: none;
            border-radius: 2px;

            font-family: "Source Sans Pro", sans-serif;
            font-weight: bold;

            color: #fff;
            background-color: #009fff;

            transition: 0.2s;
          }

          .back,
          .forward {
            margin-left: 20px;
            width: 90px;
            height: 50px;
            border-radius: 2px;
            position: relative;
            cursor: pointer;
            user-select: none;

            span {
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
              font-size: 17px;
            }
          }
          .back {
            float: left;
            margin: 10px 0 0 10%;
            background-color: $light;
            color: $dark;
          }
          .forward {
            float: right;
            margin: 10px 10% 0 0;
            background-color: $blue;
            color: white;
          }
        }
      }
    }
  }

  .content-forWhatson {
    background-color: white;
    display: inline-block;
    position: relative;
    text-align: left;
    width: 100%;
    height: 100%;
    font-style: normal;
    font-family: "Source Sans Pro", sans-serif;
  }
}
