@import "src/colorVariables.scss";
.main {
  .overlay {
    .close-overlay {
      z-index: 3;
      position: sticky;
      font-size: 28px;
      right: 24px;
      top: 10px;
      float: right;
    }
  }
}
