@import "src/colorVariables.scss";
.button {
  display: inline-flex;
  padding: 15px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border-radius: 30px;
  border: 1px solid var(--color-grey-shades-of-grey-grey-20, rgba(55, 55, 55, 0.2));
  background: var(--color-basic-white, #fff);

  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;

  // Design says 23px, but it's too big
  line-height: 16px;

  &.active {
    background: var(--color-basic-black, #000);
    color: var(--color-basic-white, #fff);
  }

  &.error {
    box-shadow: inset 0 0 0 2px $red;
  }
}
