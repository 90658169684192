@use "../../../../../breakpointVariables" as breakpoints;
@import "src/colorVariables.scss";

.content {
  display: flex;
  flex-direction: column;

  margin-top: 48px;
  gap: 50px;

  @media screen and (breakpoints.$sendistovanTablet) {
    justify-content: space-between;
    max-width: 100%;

    margin-top: unset;
    padding: 0px 20px 100px;
  }
}

.invalid_error_message {
  text-align: left;
  color: $red;
}

.dateNav {
  display: flex;
  flex-wrap: wrap;

  gap: 20px;
}

.dateSectionContainer {
  display: flex;
  flex-direction: column;
  align-items: start;

  gap: 15px;
}

.dateNavTitle {
  font-family: "Source Sans Pro", sans-serif;

  font-size: 15px;
  font-weight: 700;

  text-transform: uppercase;

  margin: 0;
}

.buttonContainer {
  display: flex;
  gap: 15px;
}

// --- DATE COMPONENT STYLES ---

.dateContent {
  display: flex;
  gap: 20px;

  @media screen and (breakpoints.$sendistovanMobile) {
    flex-direction: column;
    gap: 50px;
  }
}

.dateRenderContent {
  max-width: 730px;
  flex: 2;

  display: flex;
  flex-direction: column;

  gap: 16px;

  @media screen and (breakpoints.$sendistovanTablet) {
    flex: 1;
  }
}

// --- DATE SELECT STYLES ---

.dateSelectContainer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  gap: 20px;

  position: relative;
}

.dateSectionContainerColumn {
  display: flex;
  flex-direction: column;

  align-items: start;
  flex: 1;
}

.dateSelectDatePickerContainer {
  display: flex;
  flex-direction: column;
  gap: 2px;

  @media screen and (breakpoints.$sendistovanTablet) {
    gap: 15px;
  }
}

.toggleCheckboxContainer {
  margin-top: 12px;

  @media screen and (breakpoints.$sendistovanTablet) {
    margin-top: 20px;
  }
}

.checkbox {
  margin-bottom: 20px;

  @media screen and (breakpoints.$sendistovanTablet) {
    margin-bottom: 0;
  }
}

.toggleAndInputContainer {
  display: flex;
  gap: 30px;

  width: 100%;

  @media screen and (breakpoints.$sendistovanTablet) {
    flex-direction: column;
    align-items: start;

    gap: 15px;
  }
}

.inputContainerWrapper {
  display: flex;
  flex-direction: column;

  gap: 2px;

  width: 100%;

  @media screen and (breakpoints.$sendistovanTablet) {
    gap: 6px;
  }
}

.inputContainer {
  display: flex;
  align-items: center;

  width: 100%;
}

.inputContainerTitle {
  font-family: "Source Sans Pro", sans-serif;

  font-size: 16px;
  color: #313131;

  width: 74px;
}

// --- DATE WHEN VISIBILITY STYLES ---

.dateWhenVisibleContainer {
  max-width: 360px;

  display: flex;
  gap: 30px;

  flex: 1;

  @media screen and (breakpoints.$sendistovanMobile) {
    max-width: unset;
    flex-direction: column;

    gap: 15px;
  }
}

.divider {
  height: 100%;
  border-right: 1px solid rgba(0, 0, 0, 0.1);

  @media screen and (breakpoints.$sendistovanMobile) {
    width: 100%;
    border-right: unset;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
}

.dateWhenVisibleContentContainer {
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 20px;

  @media screen and (breakpoints.$sendistovanTablet) {
    gap: 15px;
  }
}

.dateSelectTitle,
.dateWhenVisibleTitle,
.onWhichDaysTitle {
  text-align: left;

  font-family: "Source Sans Pro", sans-serif;

  font-size: 15px;
  font-weight: 700;
  text-transform: uppercase;
}

.dateSelectTitleDate {
  @media screen and (breakpoints.$sendistovanTablet) {
    margin-bottom: 20px;
  }
}

.dateWhenVisibleInputContainerWrapper {
  position: relative;
}

.datePickerClearButton {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.dateWhenVisibleInputContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dateWhenVisibleLabel {
  font-family: "Source Sans Pro", sans-serif;

  font-size: 16px;
  font-weight: 400;
  color: #313131;

  margin: 0;
}

// --- DATE RECURRING STYLES ---

.recurringContainer {
  display: flex;
  flex-direction: column;

  gap: 60px;
}

// --- OnWhichDays STYLES ---

.onWhichDaysContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;

  text-align: left;
}

.onWhichDaysCheckboxLabel {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #313131;
}

// ----------------------------

.datePickerCustom {
  @media screen and (breakpoints.$sendistovanTablet) {
    flex-direction: column;
    align-items: start !important;

    gap: 10px;
  }
}

.close_button {
  position: absolute;
  top: 8px;
  right: 8px;

  // background-color: transparent;

  cursor: pointer;
}

.add_more_wrapper {
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .add_more {
    width: fit-content;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    gap: 4px;

    background-color: rgba(0, 0, 0, 0);

    .text {
      padding: 0;
      margin: 0;

      text-decoration: underline;
    }
  }
}

.removeTime {
  background-color: transparent;
}
