.checkboxContainer {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  position: relative; // Establish this as the reference for absolutely positioned children
}

.checkboxInput {
  opacity: 0;
  position: absolute;
  pointer-events: none;
  width: 100%;
  height: 100%;
}

// Create a container that will hold both SVGs
.svgContainer {
  position: relative;
  width: 40px; // Match your SVG width
  height: 24px; // Match your SVG height
}

// Base styles for both SVGs
.svgBase {
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 0.3s ease; // Smooth transition for the opacity
  opacity: 0; // Start invisible
  pointer-events: none; // Ensure clicks pass through to the hidden checkbox
}

.checkboxLabel {
  display: flex;
  align-items: center;
}

// Control which SVG is visible based on the :checked state of the input
.checkboxInput:checked + .checkboxLabel .svgOn {
  opacity: 1; // Show "on" state SVG
}

.checkboxInput:not(:checked) + .checkboxLabel .svgOff {
  opacity: 1; // Show "off" state SVG
}

.checkboxLabelText {
  font-family: "Source Sans Pro", sans-serif;

  margin-left: 10px;
  user-select: none;
  white-space: nowrap;
}
