.container {
  width: 100%;
  height: 100%;

  .content {
    padding: 51px 0 0 60px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    .header {
      padding-bottom: 33px;
      padding-right: 60px;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      h1 {
        font-size: 30px;
        font-weight: bold;
      }
      .buttonGroup {
        display: flex;
        gap: 20px;
      }

      .headerButton {
        width: auto;
        height: 50px;
        font-size: 17px;
        font-weight: bold;
        line-height: 21px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: 0.2s;
        min-width: 100px;

        &.addRedirect {
          background-color: #009fff;
          color: #fff;
        }

        &.endEdit {
          font-weight: 500;
          background-color: transparent;
        }

        &:hover {
          box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
          transform: translateY(-1px);
        }
      }
      .deleteRedirect {
        width: auto;
        border: none;
        background: none;
        cursor: pointer;
        color: #f64e60;
      }
    }

    .searchSection {
      padding-bottom: 31px;

      .searchInputContainer {
        position: relative;

        .searchInput {
          padding: 10px 15px;
          width: 415px;
          height: 50px;
          background-color: rgba(162, 162, 162, 0.1);
          border: none;
          transition: 0.1s;

          &:hover {
            box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
          }
        }

        .searchIcon {
          font-size: 17px;
          color: #6b6b6b;
          position: absolute;
          right: 20px;
          top: 50%;
          transform: translateY(-50%);
          opacity: 98%;
          pointer-events: none;
        }
      }
    }

    .tableContainer {
      padding-right: 60px;
      width: 100%;
      height: 100%;
      overflow: auto;

      table {
        max-height: 100%;
        height: fit-content;
        border-collapse: collapse;
        width: 100%;

        tr {
          border-bottom: 1px solid #a2a2a21a;

          td,
          th {
            text-align: left;
            padding: 8px;
            vertical-align: top;
            .checkbox {
              margin-right: 8px;
              line-height: 0;
              margin-top: 1px;
            }
          }

          th {
            font-size: 14px;
            font-weight: normal;
            color: #a2a2a2;

            &:hover {
              color: #787878;
            }
          }

          td {
            font-size: 16px;

            .edit {
              color: #a2a2a2;
              background: none;
              float: right;
              &:hover {
                color: #909090;
              }
            }

            .redirectItem {
              width: 100%;
              display: flex;
              flex-direction: row;
              justify-content: space-between;

              .delete {
                color: #f64e60;
                background: none;
                &:hover {
                  color: #000;
                }
              }
            }

            .addButton {
              background-color: transparent;
              border: none;
              color: green;
            }
            .validationError {
              color: red;
            }
          }
        }
      }
    }
  }

  .createForm {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .formField {
      label {
        color: #a2a2a2;
        font-size: 0.9rem;
        text-transform: uppercase;
      }
    }

    .validationError {
      font-size: 1rem;
      color: red;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 1rem;
      box-sizing: border-box;
      height: 30px;
    }
  }

  .modalContent {
    h1 {
      font-size: 20px;
      color: #333;
      margin: 0;
      padding: 10px 0;
      text-align: center;
      line-height: 1.5;

      b {
        font-weight: bold;
      }
    }
  }
  .editContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .buttonContainer {
    margin-top: 8px;
    display: flex;
    gap: 4px;
  }

  .saveButton,
  .cancelButton {
    border: none;
    background: none;
    cursor: pointer;
    padding: 4px;
  }

  .saveButton {
    color: green;
  }

  .cancelButton {
    color: #f64e60;
  }

  .oldPath {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .oldPathEntities {
      display: flex;
      flex-direction: column;
      gap: 0.3rem;

      label {
        margin-bottom: 0;
      }

      > label {
        display: flex;
        align-items: center;
      }
    }
  }

  .arrowIcon {
    margin-left: 8px;
    margin-top: 5px;
  }
}
.createForm {
  width: 900px;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .formRow {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .formField {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .formField label {
    margin-bottom: 8px;
    font-size: 0.9rem;
    color: #c9c8c8;
    text-align: left;
  }
  .comboSelect {
    width: 45%;
    max-width: 400px;
  }

  .comboContainerWrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    .pathText {
      padding-top: 30px;
      padding-left: 34px;
    }
  }

  .comboContainer {
    display: flex;
    flex-direction: column;
    max-width: 48%;
    width: 100%;

    .selectButton {
      padding: 10px 20px;
      cursor: pointer;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      transition: background-color 0.2s;

      &:hover {
        background-color: #e0e0e0;
      }
    }
  }

  .comboContainer label {
    margin-bottom: 8px;
    font-size: 0.9rem;
    color: #c9c8c8;
    text-align: left;
  }

  .iconWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
  .arrowIcon {
    color: #c9c8c8;
  }

  .validationError {
    color: red;
    font-size: 0.9rem;
    margin-top: -10px;
  }

  .buttonGroup {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }

  .addRedirect {
    width: auto;
    height: 40px;
    padding: 5px 15px;
    background-color: #009fff;
    font-size: 14px;
    line-height: 18px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.2s;
  }

  .cancelButton {
    width: auto;
    height: 40px;
    padding: 5px 15px;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.2s;
  }
}

.deleteOverlay {
  width: 900px;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .title {
    margin-bottom: 8px;
    font-size: 1.5rem;
    text-align: left;
  }

  .buttonGroup {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }

  .cancelButton {
    width: auto;
    height: 50px;
    padding: 10px 20px;
    font-size: 17px;
    line-height: 21px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.2s;
  }

  .deleteButton {
    width: auto;
    height: 50px;
    padding: 10px 20px;
    background-color: #e74c3c;
    font-size: 17px;
    line-height: 21px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.2s;
  }
}

.createForm {
  h2,
  p {
    margin: 0;
    text-align: left;
  }
}

.selectSite {
  padding: 16px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin: 0 auto;

  h3 {
    margin: 0;
    text-align: left;
  }

  .searchSection {
    padding-bottom: 31px;
    padding-top: 20px;

    .searchInputContainer {
      position: relative;
      width: 300px;

      .searchInput {
        padding: 10px 15px;
        width: 100%;
        height: 50px;
        background-color: rgba(162, 162, 162, 0.1);
        border: none;
        border-radius: 4px;
        transition: 0.1s;

        &:hover {
          box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
        }
      }

      .searchIcon {
        font-size: 17px;
        color: #6b6b6b;
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);
        opacity: 98%;
        pointer-events: none;
      }
    }
  }

  .siteList {
    max-height: 400px;
    overflow-y: auto;
    list-style: none;
    padding: 0;
    margin: 0;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: #fff;

    .siteItem {
      padding: 10px;
      cursor: pointer;
      border-bottom: 1px solid #eee;
      transition: background-color 0.2s;
      text-align: left;

      &:hover {
        background-color: #f0f0f0;
      }

      &:last-child {
        border-bottom: none;
      }
    }

    .noResults {
      padding: 10px;
      color: #999;
      text-align: center;
    }
  }
}
