@import "src/colorVariables.scss";
.vfi-custom-select-wrap {
  position: relative;
  &.error {
    .vfi-custom-select {
      .vfi-custom-select__control {
        border: 2px solid $red;
      }
    }
  }
  .vfi-custom-select {
    .vfi-custom-select__control {
      border-width: 1px;
      min-height: 50px;
    }

    .vfi-custom-select__indicators {
      display: none;
    }
    .vfi-custom-select__input-container {
      padding: 6px 4px;
    }
  }
  .chevron {
    position: absolute;
    font-size: 12px;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
  }
}
