@import "src/colorVariables.scss";

.body .profile-content {
  display: flex;
  flex-direction: column;
  height: calc(100% - 100px);
  overflow-y: auto;

  padding: 0 25% 20px;
  ul {
    margin: 0;
    padding: 0;
    .text {
      width: 35%;
    }
    .input {
      width: 65%;
      height: 45px;
      input {
        height: 45px;
      }
    }

    li {
      list-style-type: none;
      margin-top: 10px;
      display: inline-block;
    }

    .email {
      margin-bottom: 10px;
    }

    .name,
    .bio,
    .place,
    .contact {
      width: 100%;
      color: $mid;
      font-size: 12px;
      font-weight: 600; //Regular
      text-transform: uppercase;
      padding-top: 20px;
    }

    .buttonParent {
      margin-left: 35%;
    }

    .passwordButtons {
      margin-left: 35%;
      margin-top: 20px;
      display: flex;
      gap: 20px;
      align-items: center;
      button {
        text-align: center;
        cursor: pointer;
        display: inline-block;
        color: white;
        line-height: 16px;
        padding: 5px 15px;
        font-size: 13px;
        background-color: $blue;
      }
      .resetPswStatus {
        font-weight: 600;
        font-size: 0.8rem;
        line-height: 0.85rem;
        &.success {
          color: $green;
        }
      }
    }

    #company {
      height: 45px;
      padding: 10px 25px 10px 12px;
      background-color: $input-fill;
      border: none;
      border-radius: 2px;
      border: 2px solid transparent;
      position: relative;
      width: 100%;
    }

    #company:hover {
      box-shadow: 0px 0px 2px #919191;
    }
  }

  .settings-header {
    color: $mid;
    opacity: 0.98;
    font-size: 0.75rem;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 15px;

    margin-top: 4rem;

    text-transform: uppercase;

    border-bottom: 1px solid rgba(162, 162, 162, 0.2);
    padding-bottom: 13px;

    width: 100%;
  }

  .revoke-access-setting {
    display: flex;
    gap: 120px;
    margin-top: 15px;

    .revoke-access-title,
    .revoke-access-btn {
      opacity: 0.98;
    }

    .revoke-access-title {
      color: $dark;
      line-height: 20px;
    }
    .revoke-access-btn {
      color: #000000;
      font-size: 15px;
      line-height: 19px;

      text-decoration: underline;
      cursor: pointer;
    }
  }

  .notification-settings-container {
    margin-top: 20px;
  }

  .show-view {
    position: absolute;
    right: 1rem;
    top: 10px;

    display: inline-block;
    background-color: $blue;

    width: fit-content;
    padding: 10px 20px;
    // margin-top: auto;

    color: white;
    // padding: 2px 5px;
    user-select: none;
    cursor: pointer;
  }

  .profile-content-container {
  }

  .profile-content-button-container {
    display: flex;
    justify-content: center;
    align-items: center;

    margin-top: 100px;
  }
}

.body .permission-content {
  padding: 0 25%;
  height: calc(100% - 100px);
  overflow-y: auto;

  .place-table {
    margin-top: 20px;
    .place-names-title {
      padding-right: 20px;
    }
    .permission-place-table {
      .td-checkbox {
        text-align: center;
      }
    }
  }

  ul {
    margin: 0;
    padding: 0;

    li {
      list-style-type: none;
      margin: 15px 0;
      display: inline-block;
      max-height: 24px;
    }

    .admin,
    .publisher,
    .collaborator,
    .site,
    .region {
      color: $mid;
      font-size: 12px;
      font-weight: 600;
      padding-top: 20px;
    }
    .site {
      width: 70%;
    }
    .admin,
    .publisher,
    .collaborator {
      width: 15%;
      text-align: center;
    }

    .listsite {
      width: 70%;
      .small-gray {
        font-size: 0.75rem;
        color: $mid;
        padding-left: 1rem;
      }
    }

    .listregion {
      width: 85%;
    }

    .checkbox {
      width: 15%;
      text-align: center;
      .vfi-checkbox-2 {
        width: 14px;
        height: 14px;
      }
    }

    &.SuperAdminParent {
      //NOT RIGHT
      height: 50px;
      width: 100%;
      display: flex;
      margin-top: 40px;
      // .superAdminLine {
      //   width: 50%;
      //   height: 1px;
      //   border-top: 1px solid #f5f5f5;
      //   margin: 40px auto 0 auto;
      //   display: block;
      // }
      .superAdmin {
        width: 85%;
        flex-grow: 1;
      }
      .checkbox {
        width: 15%;
      }
    }
    .profession-parent {
      display: flex;
      justify-content: space-between;
      padding-right: 50px;
      margin-top: 20px;
      .select {
        width: 250px;
        margin-top: 8px;
      }
    }
  }

  .pageListing,
  .regionListing {
    &.checked {
      background-color: black;
      color: white;
    }
  }

  .pageListing {
    border-bottom: 1px solid rgba(162, 162, 162, 0.2);
    display: flex;
    position: relative;
    &.head {
      padding-bottom: 12px;
    }
    .remove-element {
      position: absolute;
      right: -30px;
      top: 50%;
      transform: translateY(-50%);
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      opacity: 0;
      transition: ease-out 0.2s;
    }
    &:hover {
      .remove-element {
        opacity: 1;
      }
    }
  }

  .add-new-button {
    padding: 12px;
    font-size: 0.9rem;
    width: fit-content;
    user-select: none;
    cursor: pointer;
    svg {
      margin-left: 10px;
    }
  }

  .regionListing {
    transition: ease-in-out 0.2s;
    padding: 5px 15px;
    border-radius: 100px;
    cursor: pointer;
    user-select: none;
    margin-right: 10px;
    background-color: $light;
    margin-top: 12px;
  }

  .regions {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .overlay-element {
    .small {
      font-size: 0.8rem;
      color: $mid;
      font-weight: 600;
      margin-top: 30px;
      margin-bottom: 10px;
    }
  }
}

.body .activity {
  padding: 0 5%;
  height: 100%;
  overflow-y: auto;

  .search-parent {
    display: inline-block;
    width: 50%;
    .search {
      position: relative;
      width: 70%;
      margin: 30px 0;
      input {
        //background-color: rgba(162,162,162,0.1);
        //border-radius: 2px;
        //border: 0;
        //padding: 12px 6px;sssss
        width: 100%;

        ::placeholder {
          font-size: 2px;
        }
      }
      .magnifying-glass {
        position: absolute;
        top: 45%;
        right: 12px;
        transform: translateY(-50%);
        pointer-events: none;
      }
    }
  }

  .all-sort-and-filter {
    display: inline-block;
    width: 50%;
    text-align: right;
    margin-top: 20px;
    .image-show,
    .list-show,
    .filter-button {
      cursor: pointer;
      display: inline-block;
      margin-left: 35px;
      padding: 5px 10px;
      transition: ease-in-out 0.5s;
      border-radius: 2px;
      .icon {
        margin: auto;
      }
    }

    .image-show.selected,
    .list-show.selected {
      color: #009fff;
    }

    .filter-button {
      padding: 9px 15px 9px 20px;
      border: 1px solid transparent;
      border-radius: 20px;
      user-select: none;
      background-color: $blue;
      color: white;
      .slide-icon {
        margin-left: 15px;
      }
    }
    .filter-button.hidden {
      padding: 9px 15px 9px 20px;
      border: 1px solid rgba(162, 162, 162, 0.1);
      border-radius: 20px;
      user-select: none;
      background-color: white;
      color: black;
      cursor: pointer;
      .slide-icon {
        margin-left: 15px;
      }
    }
    .filter-button.hidden {
      padding: 9px 15px 9px 20px;
      border: 1px solid rgba(162, 162, 162, 0.1);
      border-radius: 20px;
      user-select: none;
      cursor: pointer;
      .slide-icon {
        margin-left: 15px;
      }
    }
  }

  .activity-table {
    width: 100%;
    margin-bottom: 40px;
    th {
      font-size: 14px;
      font-weight: 600;
      color: #a2a2a2;
      opacity: 0.98;
      padding: 10px 0;
      user-select: none;
      transition: ease-in-out 0.2s;
      svg {
        margin-left: 9px;
      }
    }
    th.post,
    th.site,
    th.activity,
    th.date {
      cursor: pointer;
    }
    th.page {
      width: 55%;
    }
    th.site,
    th.activity {
      width: 17%;
    }

    th.date {
      width: 11%;
    }

    th:hover {
      color: #626262;
    }
    th.sorted-by {
      color: black;
    }

    tbody {
      tr {
        padding: 0;
        margin: 0;

        td {
          padding: 9px 0;
          height: 60px;
          font-size: 14px;
          border-top: 1px solid rgba(162, 162, 162, 0.1);
        }

        .page {
          img {
            height: 100%;
            width: 70px;
            margin: auto;
            object-fit: cover;
            margin-right: 2%;
          }
        }

        td.Date {
          font-size: 13px;
          color: $mid;
        }

        td.Activity {
          svg {
            margin-right: 5%;
          }
        }
      }
    }
  }
}
